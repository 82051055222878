
// Modified https://coolcssanimation.com/how-to-trigger-a-css-animation-on-scroll/
function scrollSpy(selector, animationClass, delay) {
  const animationObject = document.querySelectorAll(selector);

  for (let element of animationObject) {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (delay !== undefined) {
              setTimeout(function () {
                element.classList.add(animationClass);
              }, delay);
            } else {
              element.classList.add(animationClass);
            }
            return;
          }
          // We're not intersecting, so remove the class! This enables the repeat animation.
          // element.classList.remove(animationClass);
        });
      },
      { rootMargin: '-50px' }
    );
    observer.observe(element);
  }
}
